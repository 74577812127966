

<template>
    <div class="enps-wrapper">
        <scale-list :scales="question.questionChoices" :initialSelectedId="selected" :instruction="question.instruction" @picked="selectChoice" />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import ScaleList from './ScaleList.vue';
export default {
    name: "EnpsQuestion",
    components: { ScaleList },
    emits: ['changed'],
    props: {
        question: {
            type: Object,
            required: true,
            default: () => {},
        },
    },
    computed: {
        computedScales() {
            return this.question.questionChoices.map(this.convertQuestionChoice);
        },
        selected(){
            if(this.question.savedAnswer && this.question.savedAnswer.questionChoiceIds && this.question.savedAnswer.questionChoiceIds.length > 0){
                return this.question.savedAnswer.questionChoiceIds[0]; 
            }
            return null;
        },
        
    },
    mounted() {
        
        
    },
    methods: {
        ...mapActions(['postSpecificQuesion']),
        selectChoice(choiceId){
            let choice = this.question.questionChoices.find(ch => ch.id === choiceId);
            let questionAnswerIm = { questionChoiceIds: [choice.id],questionId: this.question.id, comment: null }
            if(this.question.savedAnswer !== null){
                questionAnswerIm.comment = this.question.savedAnswer.comment
            }
            this.$emit('changed', {choiceValue: choice.numericValue, commentType: this.question.commentType, questionType: this.question.questionType });
            this.postSpecificQuesion(questionAnswerIm);
        },
        convertQuestionChoice(qc){
            return {qc: qc, state: '' };
        },
    },

    
}
</script>
<style lang="scss" scoped>

</style>