<template>
    <div class="progress-bar-wrapper">
        <div class="inner">
            <div class="values-wrapper notranslate">
                {{valueComputed}}/{{maxComputed}}
            </div>
            <div class="w-100">
                <pop-progress class="mx-auto">
                    <pop-progress-bar
                    :value="valueComputed"
                    :max="maxComputed"
                    animated
                    />
                </pop-progress>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import PopProgress from "./PopProgress.vue";
import PopProgressBar from "./PopProgressBar.vue";
export default {
    name: "ProgressBar",
    components: {
        PopProgress,
        PopProgressBar,
    },
    computed:{  
        ...mapGetters(['getNumberOfAnsweredQuestion', 'isSurveyAnswerBaseModelLoaded']),
        valueComputed(){
            if(!this.isSurveyAnswerBaseModelLoaded){
                return 0;
            }
            return this.getNumberOfAnsweredQuestion.value;
        },
        maxComputed(){
            if(!this.isSurveyAnswerBaseModelLoaded){
                return 0;
            }
            return this.getNumberOfAnsweredQuestion.max;
        },
    },
}
</script>

<style lang="scss" scoped>
.progress-bar-wrapper {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    width: 100%;
    .inner {
        max-width: 440px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        margin: 0 auto;
        height: 45px;
    }
    .progress-wrapper {
        width: 85%;
    }
    .values-wrapper {
        width: 15%;
    }
}
</style>