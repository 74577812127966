<template>
    <div class="leagal-wrapper box highlighable" id="tos-container-id" :class="getHighlighStyle">
        <div class="inner">
            <div class="content">
                <h2 class="side-padding heading-size">{{$t("privacyPolicy.header")}}</h2>
                <p v-if="showHighlighText" class="side-padding" :class="getHighlighTextStyle">{{$t('privacyPolicy.needToAccept')}}</p>
                <pop-checkbox choiseId="legal" :questionId="getContentOfLegalModel" :isClicked="checkBoxClicked" :choice="''" :large="true">
                    <template v-slot:choice>
                        <i18n-t keypath="privacyPolicy.iAccept" tag="span">
                            <template v-slot:link>
                                <a href="#!" @click="showModal">{{$t("privacyPolicy.link")}}</a>
                            </template>
                        </i18n-t>
                    </template>
                </pop-checkbox>
            </div>
        </div>
        <pop-modal
        :model-value="isModalShown"
        :show-close="false"
        size="lg"
        @update:model-value="hideModal"
        >
            <div class="modal-padding v-html-legal grey-wrapper" v-html="getPolicySummary"></div>
            <div class="modal-padding v-html-legal" v-html="getPolicyComputed"></div>
            <template #modal-footer>
            <pop-button-component
                variant="primary" 
                block
                @click="hideModal">
                {{$t('shared.close')}}
            </pop-button-component>
        </template>
        </pop-modal>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PopCheckbox from './PopCheckbox.vue';
import PopModal from './PopModal.vue';
import PopButtonComponent from './PopButtonComponent.vue';

export default {
    name: "LegalModelAcceptCard",
    components: {
        PopCheckbox,
        PopModal,
        PopButtonComponent,
    },
    computed: {
        ...mapGetters(['getContentOfLegalModel', 'highlightedHasBeenTriggered', 'userHasAcceptedToS']),
        getPolicyComputed(){
            return this.getContentOfLegalModel.policy;
        },
        getHighlighStyle(){
            return this.highlightedHasBeenTriggered &&  !this.userHasAcceptedToS ? 'unanswered-style' : '';
        },
        showHighlighText(){
            return this.highlightedHasBeenTriggered;
        },
        getHighlighTextStyle(){
            return this.highlightedHasBeenTriggered &&  !this.userHasAcceptedToS ? 'unanswered-style-text' : '';
        },
        getPolicySummary(){
            return this.$t('privacyPolicy.policySummary');
        },
    },
    data() {
        return {
            isModalShown: false,
        }
    },
    methods: {
        ...mapActions(['acceptLegalModel']),
        checkBoxClicked(hasAccepted){
            this.acceptLegalModel(hasAccepted)
        },
        showModal(){
            this.isModalShown = true;
        },
        hideModal(){
            this.isModalShown = false;
        },
    },
}
</script>

<style lang="scss" scoped>
.leagal-wrapper {
    max-width: 440px;
    margin: 0 auto;
    list-style: none;
    min-height: 20vh;
    margin-top: 2em;
    margin-bottom: 2em;
    .inner {
        border-radius: 3px;
        height: auto;
        margin: 10px;
        .content{
            padding: 2em 0 1.5em 0;
            .side-padding{
                padding: 0 30px;
            }
            .heading-size{
                font-size: 1.5em;
            }
        }
    }
}

</style>