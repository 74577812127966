<template>
    <div class="section-intro-wrapper already-answered-card">
        <div class="inner">
            <h2>{{$t('survey.alreadySubmitedHeader')}}</h2>
            <template v-if="expirationTimeComputed">
                <div>{{$t('survey.alreadySubmitedBody1')}}</div>
                <i18n-t keypath="survey.alreadySubmitedBody2" tag="div">
                    <template v-slot:time>
                        <span class="time-expression">{{ expirationTimeComputed }}</span>
                    </template>
                </i18n-t>
            </template>
            <div v-else>
                {{ $t('error.expired.description', {expirationTime: expirationTextComputed}) }}
            </div>
            <div class="mt-2">
                <pop-button-component type="button" variant="primary" size="sm" :href="appHrefComputed">{{$t('shared.home')}}</pop-button-component>
            </div>  
        </div>
    </div>
</template>

<script>
import PopButtonComponent from './PopButtonComponent.vue';
import { formatDistanceStrict } from 'date-fns'
import { languages } from "../services/i18n.js"
import { mapGetters } from "vuex";

let expirationString = (expiration, language) => new Date(expiration).toLocaleString(language, {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit'});

let comparedCurrentTimeTimeout = function(expiration){
    let seconds;
    let secondsDifference = (expiration - Date.now())/1000;
    if(secondsDifference < 0){
        seconds = 3600;
    }
    else if(secondsDifference > 60 * 60){
        seconds = 60;
    } else if(secondsDifference > 3 * 60){
        seconds = 30;
    } else if(secondsDifference > 1 * 60){
        seconds = 10;
    } else if(secondsDifference > 1 * 15){
        seconds = 5;
    } else{
        seconds = 1;
    }

    return seconds * 1000;
}

export default {
    name: "AlreadyAnsweredCard",
    components: {
        PopButtonComponent,
    },
    props: {
        expirationDate: {
            type: Date,
            required: true,
            default: new Date(),
        },
        subDomain:{
            type: String,
            default: "app",
        }
    },
    data() {
        return {
            dateNow: Date.now(),
        }
    },
    created () {
        setTimeout(this.updateComparedCurrentTime, comparedCurrentTimeTimeout(this.expirationDate));
    },
    computed: {
        ...mapGetters(['getUserLanguage']),
        expirationTimeComputed() {
            let addSuffix = false;
            if(this.expirationDate - this.dateNow < 0){
                return null;
            }
            return formatDistanceStrict(this.dateNow, this.expirationDate, {
                locale: languages[this.getUserLanguage].dateFns,
                addSuffix,
            })
        },
        appHrefComputed(){
            return `https://${this.subDomain}.populum.se`;
        },
        expirationTextComputed(){
            return expirationString(this.expirationDate, this.getUserLanguage);
        }
    },
    methods: {
        updateComparedCurrentTime() {
            this.dateNow = Date.now();
            setTimeout(this.updateComparedCurrentTime, comparedCurrentTimeTimeout(this.expirationDate));
        },
    },
}
</script>

<style lang="scss" scoped>
.already-answered-card {
    max-width: 440px;
    margin: 0 auto;
    list-style: none;
    .inner {
        background-color: white;
        border-radius: 3px;
        height: auto;
        margin: 10px;
        padding: 2em;
        position: relative;
        overflow: hidden;
        &::before {
            content: "";
            width: 100%;
            height: 10px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            border-radius: 3px 3px 0 0;
        }
        h2 {
            font-size: 1.2em;
        }
        small {
            font-weight: 300;
        }
        p {
            font-size: 0.9em;
        }
        .time-expression{
            font-weight: 500;
        }
    }
}
</style>