import axios from 'axios';
import axiosRetry from 'axios-retry';
import ConfigService from './configuration'
import { ApiTypes } from '../helpers/constants'
import { Functions } from '../helpers/functions';
import applicationInsights from '../helpers/applicationInsights.js'

const Instance = {
    configure: async function (type, options) {
        let { retries = 1 } = options;
        let apiUrl = ConfigService.ApiUrl();
        if(window.location.search.indexOf("a=w") !== -1){
            apiUrl = ConfigService.WindowsApiUrl();
        }
        const axiosInstance = axios.create({
            baseURL: `${apiUrl}/answer/${type}`,
            withCredentials: true,
        });
        axiosRetry(axiosInstance, { retries: retries, retryDelay: (retryCount) => retryCount * retryCount * 500 });
        axiosInstance.interceptors.request.use(async (config) => {
            const retryState = config['axios-retry'] || {};
            if (retryState.retryCount > 0) {
                config.headers['x-retry-count'] = retryState.retryCount;
            }
            if (type === ApiTypes.user) {
                await import('./authentication')
                    .then(({ default: AuthService }) => AuthService.getAcessToken())
                    .then(tokenResponse => config.headers.Authorization = `Bearer ${tokenResponse}`);
            }
            if (config.method === "post") {
                config.headers['Populum.Survey.Api.XSRF'] = this.xsrfToken;
            }
            return config;
        }, (error) => {
            Functions.logError(error, "Token error");
            return Promise.reject(error);
        });
        let setUserIdFromResponse = (response) => {
            let userId = response.data?.employee?.id ?? response.data?.data?.employeeId;
            if(type === ApiTypes.key && userId) {
                applicationInsights.setAuthenticatedUserContext(`${userId}`);
            }
        };
        axiosInstance.interceptors.response.use(async (response) => {
            if (response.config.method === 'get') {
                this.xsrfToken = response.headers['Populum.Survey.Api.XSRF'.toLowerCase()] || this.xsrfToken;
            }
            setUserIdFromResponse(response);
            return response;
        }, (error) => {
            setUserIdFromResponse(error?.response);
            if (error?.response?.status >= 500) {
                if (error.config?.headers?.Authorization) {
                    error.config.headers.Authorization = "xxx";
                }
                if (error.response.config?.headers?.Authorization) {
                    error.response.config.headers.Authorization = "xxx";
                }
                Functions.addSentryBreadcrumb("api-call", "Api call returned error response", { httpStatus: error.response.status, url: error.config.url, method: error.config.method });
            }
            else if (!error?.response) {
                Functions.addSentryBreadcrumb("api-call", "No error response", { error: error });
            }
            return Promise.reject(error);
        });
        return axiosInstance;
    },
    call: async function (type, apiCall, options = {}) {
        return this.configure(type, options).then(apiCall);
    },
}
export default Instance;
