<template>
    <div class="question-wrapper box" :class="getHighlighStyle">
        <div class="inner">
            <div class="content">
                <slot name="questionInstruction" :paddingLeft="paddingLeft"></slot>
                <slot name="question" :paddingLeft="paddingLeft"></slot>
            </div>
            <slot name="nextQuestionButton"></slot>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "QuestionWrapper",
    props: {
        questionId: {
            type: Number,
            required: true,
        },
        isInstructionWithoutChoices: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            paddingLeft: 2,
        };
    },
    computed: {
        ...mapGetters(['isQuestionHighlighted']),
        getHighlighStyle(){
            if (this.isInstructionWithoutChoices){
                return 'instruction-question';
            }

            return this.isQuestionHighlighted(this.questionId) ? 'highlighable unanswered-style' : 'highlighable';
        },
    },

}
</script>

<style lang="scss" scoped>
.question-wrapper {
    max-width: 440px;
    margin: 0 auto;
    list-style: none;
    min-height: 20vh;
    &.instruction-question{
        min-height: 0 !important;
        div.inner{
            border-radius: 3px;
            height: auto;
            position: relative;
            overflow: hidden;
            &::before {
                content: "";
                width: 100%;
                height: 10px;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                border-radius: 3px 3px 0 0;
            }
        }
        
    }
    .inner {
        background-color: white;
        border-radius: 3px;
        height: auto;
        margin: 10px;

        .content{          
            padding-bottom: 2em;
        }
    }
}
</style>