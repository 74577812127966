<template>
    <div class="introduction-modal">
        <pop-modal 
            :id="modalId"
            :model-value="modalShow" 
            :show-close="false"
            size="xl" 
            @update:model-value="$emit('update:modelValue')"
        >
            <div class="survey-content-container">
                <div class="survey-logo" v-if="surveyLogoComputed">
                    <img :src="surveyLogoComputed" :alt="surveyNameComputed" :title="surveyNameComputed" />
                </div>
                <h1 class="content">{{survey.name}}</h1>                    
                <div class="language-select form-group content" v-if="showSelectLanguagesComputed">
                    <label class="language" for="intro-language">{{$t("welcome.language")}}: </label>
                    <select-language input-id="intro-language" />
                </div>
                <div class="content">
                    <div v-html="surveyInstructions" class="v-html"></div>
                </div>
            </div>
            <div v-if="showPartOfManagerGroupComputed" class="grey-wrapper">
                <div class="survey-content-container">
                    <div class="content">
                        <strong v-if="hasManagerComputed">{{$t('welcome.yourManagerGroup')}} {{managerGroupNameComputed}}</strong>                     
                        <strong v-else v-t="'welcome.noManager'" />                     
                    </div>
                    <div 
                        v-if="canValidateOrganizationComputed"
                        class="text-muted font-italic content"
                    >
                        {{$t('welcome.organizationDisclamer')}}
                    </div>
                </div>
            </div>
        <template #modal-footer>
            <pop-button-component
                variant="primary" 
                block
                @click="$emit('update:modelValue')">
                {{$t('welcome.getStarted')}}
            </pop-button-component>
        </template>
        </pop-modal>
    </div>
</template>

<script>
import { Functions } from '../helpers/functions';
import SelectLanguage from "./SelectLanguage.vue";
import { mapGetters } from "vuex";
import PopModal from "./PopModal.vue";
import PopButtonComponent from './PopButtonComponent.vue'
export default {
    name: "IntroductionModal",
    components:{
        SelectLanguage,
        PopModal,
        PopButtonComponent
    },
    props:{
        survey: {
            type: Object,
            required: true,
            default: () => {},
        },
        organization: {
            type: Object,
            required: true,
            default: () => {},
        },
        modalId:{
            type: String,
            required: true,
        },
    },
    emits: ['update:modelValue'],
    computed:{
        ...mapGetters(['getAvailableLanguages']),
        surveyInstructions(){
            if(this.survey != null){
                return Functions.renderMarkdown(this.survey.instruction);
            }
            return '';
        },
        managerGroupNameComputed(){
            let names = [];
            if(this.organization.managerName?.trim().length > 0){
                names.push(this.organization.managerName)
            }
            if(this.organization.groupName?.trim().length > 0){
                names.push(this.organization.groupName);
            }
            if(names.length > 0){
                return names.join(' / ');
            }
            if(this.organization.managerTitle?.trim().length > 0){
                return this.organization.managerTitle;
            }
            return `(${this.$t("welcome.vacantManager")})`;
        },
        showPartOfManagerGroupComputed(){
            return !this.organization.hidePartOfManagerGroup;
        },
        hasManagerComputed(){
            return !this.organization.noManager;
        },
        surveyLogoComputed(){
            return this.organization.surveyLogoUrl;
        },
        surveyNameComputed(){
            return this.survey.name;
        },
        showSelectLanguagesComputed(){
            return this.getAvailableLanguages.length > 1;
        },
        canValidateOrganizationComputed(){
            return !this.organization.noOrganizationValidation;
        },
    },
    data() {
        return {
            modalShow: true,
        }
    },
}

</script>

<style lang="scss" scoped>

.modal-content {
    .modal-body {
        h1, h2 {
            font-size: 1.4em;
        }
        p {
            max-width: inherit;
            font-size: 0.9em;
        }
    }
}
.survey-content-container{
    max-width: 60ch;
    margin: 0 auto;
    h1 {
        word-wrap: break-word;
        hyphens: auto;
    }
    .content{
        margin: 0.75rem;
    }
    .language-select .language{
        font-size: 0.8em;
        font-weight: 500;
    }

    .survey-logo{
        text-align: center;
        img{
            max-width:100%;
            max-height:200px;

            @media only screen and (max-width: 768px) { //No access to $breakpoint-md here...
                max-height: 150px;
            }
        }
        
    }
    // lists
    ul, ol {
        margin-left: 1em;
        li {
            padding-bottom: 5px;
        }
        &.checkbox {
            li {
                list-style-type: "\2714";
                p {
                    margin-left: 10px;
                    font-size: 0.9em;
                }
            }
        }
    }
}

.grey-wrapper {
    padding: 2rem 0;
}

</style>