import { Environments } from './constants';
import { Functions } from './functions'

// Inspired from https://stackoverflow.com/questions/46730904/user-switchable-custom-themes-with-vue-js
export const ThemeHelper = function () {
    const setTheme = (filePath) => {
        let cssLinkId = import.meta.env.VITE_THEME_CSS_ID;
        let fileEnding = "";
        switch (import.meta.env.VITE_ENV) {
            case Environments.local:
                fileEnding = '.css';
                break;
            case Environments.dev:
            case Environments.staging:
            case Environments.prod:
                fileEnding = `${import.meta.env.VITE_THEME_CSS_HASH}.css`;
                break;
        }
        filePath += fileEnding;
        let currentStyleSheat = document.getElementById(cssLinkId);
        if (currentStyleSheat.href.endsWith(filePath)) {
            return new Promise.resolve(currentStyleSheat);
        }
        let link = document.createElement("link");
        link.rel = "stylesheet";
        link.disabled = false;
        link.href = filePath;

        return new Promise((resolve, reject) => {
            link.onload = event => {
                // Remove the onload() event listener after initial load, because some
                // browsers (like Firefox) could call onload() later again
                link.onload = null;
                let sheetExisted = false;
                for (let index = document.styleSheets.length - 1; index >= 0; index--) {
                    const sheet = document.styleSheets[index];
                    if(sheet.href?.endsWith(filePath) && sheet.cssRules?.length > 0){
                        sheetExisted = true;
                        break;
                    }
                }
                if(sheetExisted){
                    currentStyleSheat.parentNode.removeChild(currentStyleSheat); //currentStyleSheat.remove() doesn't exist in IE11
                    link.id = cssLinkId;
                }
                else{
                    link.parentNode.removeChild(link); //link.remove() doesn't exist in IE11
                    Functions.logMessage("warning", `Failed to load css theme: ${filePath}`);
                    reject(event);
                }
                resolve(link);
            };
            link.onerror = event => {
                link.remove();
                reject(event);
            };
            document.head.appendChild(link);
        });
    };
    return {
        setTheme,
    };
};