import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'


const routes = [
    {
        path: '/',
        redirect: '/NotFound',
    },
    {
        path: '/k/:accessKey',
        name: 'Survey',
        component: Home,
    },
    {
        path: '/u/:surveyPushId',
        name: 'Survey as user',
        component: Home,
    },
    {
        path: '/preview',
        name: 'Preview survey as user',
        component: Home,
        props: { isPreview: true },
    },
    {
        path: '/JsSignIn',
        name: 'sign in',
        component: () => import('../views/SignIn.vue'),
    },
    {
        path: '/NotFound',
        name: 'NotFound',
        component: () => import('../views/NotFound.vue'),
    },
    {
        path: '/NotAuthorized',
        name: 'NotAuthorized',
        component: () => import('../views/NotAuthorized.vue'),
    },
    {
        path: '/Expired',
        name: 'Expired',
        component: () => import('../views/Expired.vue'),
        props: true,
    },
    {
        path: '/Removed',
        name: 'Removed',
        component: () => import('../views/Removed.vue'),
    },
    {
        path: '/KeyConsumed',
        name: 'KeyConsumed',
        component: () => import('../views/KeyConsumed.vue'),
    },
    {
        path: '/NoActive',
        name: 'NoActive',
        component: () => import('../views/NoActive.vue'),
    },
    {
        path: '/Info/Build',
        name: 'BuildVersion',
        component: () => import('../views/BuildVersion.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'Error',
        component: () => import('../views/NotFound.vue'),
    },
]
const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
})

export default router
