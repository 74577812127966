import { createStore } from 'vuex'
import { LoadingState, ApiTypes, SurveyAnswerStatus } from '../helpers/constants'
import { Functions } from '../helpers/functions'
import Instance from '../services/repository';
import debounce from 'lodash.debounce';
import router from '../router/index'
import { loadLanguageAsync } from '../services/i18n'


const store = createStore({
    state: {
        surveyAnswerBaseModel: {
            loadingState: LoadingState.notStarted,
            data: null,
            changesToSubmit: false,
        },
        error: null,
        dataParameters: null,
        debouncedPostSpecificQuesion: new Map(),
        debouncedPostSpecificComment: new Map(),
        waitingPromises: [],
        cssTheme: "default",
        cssThemeIsLoading: false,
        highlightUnansweredQuestions: false,
        userLanguage: "en",
        userLanguageToLoad: null,
        isSubmitting: false,
        submitSuccess: false,
        isPreview: false,
    },
    mutations: {
        setDataIsLoading(state) {
            state.surveyAnswerBaseModel.loadingState = LoadingState.pending;
        },
        dataFailedToLoad(state) {
            state.surveyAnswerBaseModel.loadingState = LoadingState.failure;
        },
        dataLoadedSuccessful(state, data) {
            state.cssTheme = data.organization.theme;
            state.surveyAnswerBaseModel.data = data;
            state.surveyAnswerBaseModel.changesToSubmit = SurveyAnswerStatus.partiallyAnswered === data.survey.surveyAnswerStatus;
            state.waitingPromises = [];

            state.surveyAnswerBaseModel.loadingState = LoadingState.success;
        },
        setDataParameters(state, data) {
            state.dataParameters = data;
        },
        answerQuestion(state, { questionAnswerIm, allQuestions }) {
            let question = allQuestions.find(q => q.id === questionAnswerIm.questionId);
            question.savedAnswer = { ...questionAnswerIm };
            state.surveyAnswerBaseModel.changesToSubmit = true;
            state.submitSuccess = false;
        },
        addDebounceFunction(state, { key, value }) {
            state.debouncedPostSpecificQuesion.set(key, value);
        },
        addDebounceCommentFunction(state, { key, value }) {
            state.debouncedPostSpecificComment.set(key, value);
        },
        setToSubmitted(state) {
            /*  Setting isSubmitted=true Stops debounce functions 
                from sending data to server.
                This is important becouse otherwise rowversion check 
                can fail server side cousing the submit to fail.
            */
            state.surveyAnswerBaseModel.data.survey.isSubmitted = true;
        },
        flushOutstandingDebounces(state) {
            state.debouncedPostSpecificComment.forEach((value) => value.flush());
            state.debouncedPostSpecificQuesion.forEach((value) => value.cancel());
        },
        addToPromiseList(state, promise) {
            state.waitingPromises.push(promise);
        },
        clearAllPromises(state) {
            state.waitingPromises = [];
        },
        surveyAnswerSent(state) {
            state.surveyAnswerBaseModel.data.survey.surveyAnswerStatus = SurveyAnswerStatus.completed;
            state.surveyAnswerBaseModel.data.survey.isSubmitted = true;
            state.surveyAnswerBaseModel.changesToSubmit = false;
            state.submitSuccess = true;
        },
        handleErrorWhenSubmit(state) {
            state.surveyAnswerBaseModel.data.survey.isSubmitted = false;
            state.surveyAnswerBaseModel.changesToSubmit = true;
            state.submitSuccess = false;
        },
        acceptLegalModel(state, hasConsentedToTerms) {
            state.surveyAnswerBaseModel.data.employee.legalModel.hasConsentedToTerms = hasConsentedToTerms;
        },
        highlightUnansweredQuestions(state) {
            state.highlightUnansweredQuestions = true;
        },
        setUserLanguage(state, language) {
            state.userLanguageToLoad = null;
            state.userLanguage = language;
        },
        setUserLanguageToLoad(state, language) {
            state.userLanguageToLoad = language;
        },
        setIsSubmitting(state, submit) {
            state.isSubmitting = submit;
        },
        setThemeIsLoading(state) {
            state.cssThemeIsLoading = true;
        },
        setThemeFinishedLoading(state) {
            state.cssThemeIsLoading = false;
        },
        setIsPreview(state) {
            state.isPreview = true;
        },
        setError(state, error) {
            state.error = error;
        }
    },
    actions: {
        setDataParameters({ commit }, dataParameters) {
            commit('setDataParameters', dataParameters);
        },
        async fetchSurveyAnswerBaseModel({ commit, dispatch, state, getters }) {
            let endpoint = '';
            if (getters.isPreview) {
                endpoint = 'preview'
            }
            commit('setDataIsLoading');
            try {
                const result = await Instance.call(getters.getType, api => api.get(endpoint, { params: state.dataParameters }));
                if (result.status === 200) {
                    dispatch('dataLoadedSuccessful', result.data);
                }
            } catch (error) {
                if (error.response?.data?.error) {
                    if (error.response?.data?.data.languageIso) {
                        dispatch('updateUserLanguage', error.response.data.data.languageIso);
                    }
                    commit('setError', error.response.data)
                    router.push({ path: `/${error.response.data.error}`, name: error.response.data.error})
                } else if (error.response?.status === 403) {
                    router.push('/NotAuthorized');
                }
                else {
                    commit('dataFailedToLoad');
                }
            }
        },
        setIsPreview({ commit }) {
            commit('setIsPreview');
        },
        dataLoadedSuccessful({ commit, dispatch }, data) {
            dispatch("updateUserLanguage", data.survey.currentLanguage.languageIso);
            commit('dataLoadedSuccessful', data);
        },
        postSpecificQuesion({ commit, dispatch, state, getters }, questionAnswerIm) {
            const endpoint = 'question';
            const debounceTime = 1000;
            const allQuestions = getters.getAllQuestions;
            commit('answerQuestion', { questionAnswerIm, allQuestions });
            if (!getters.isPreview && !state.surveyAnswerBaseModel.data.survey.isSubmitted && questionAnswerIm != null && !state.isSubmitting) {
                let postQuestion = state.debouncedPostSpecificQuesion.get(questionAnswerIm.questionId);
                if (postQuestion === undefined) {
                    postQuestion = debounce(() => {
                        const surveyAnswer = { ...getters.getAnswer(questionAnswerIm.questionId) };
                        if (surveyAnswer.comment?.trim() === '') {
                            surveyAnswer.comment = null;
                        }
                        let promise = Instance.call(getters.getType, api => api.post(endpoint, surveyAnswer, { params: state.dataParameters }), { retries: 0 });
                        commit('addToPromiseList', promise);
                    }, debounceTime, { leading: true, trailing: true });
                    commit('addDebounceFunction', { key: questionAnswerIm.questionId, value: postQuestion });
                }
                postQuestion();
            }
            const answeredHiddenQuestions = allQuestions.filter(q => !Functions.isQuestionVisible(q, allQuestions) && q.savedAnswer && (q.savedAnswer.comment || q.savedAnswer.questionChoiceIds.length > 0));
            for (let questionToClear of answeredHiddenQuestions) {
                dispatch("postSpecificQuesion", { questionChoiceIds: [], questionId: questionToClear.id, comment: null });
            }
        },
        createDebouncedPostSpecificComment({ commit, getters }, { questionId, func }) {
            const debounceTime = getters.isPreview ? 0 : 4000;
            let debounceFunc = debounce(func, debounceTime, { leading: false, maxWait: 5000, trailing: true });
            commit('addDebounceCommentFunction', { key: questionId, value: debounceFunc });
        },
        async submitSurveyAnswer({ commit, dispatch, getters, state }) {
            const endpoint = '';
            if (getters.isReadyToSubmit && !getters.isPreview) {
                commit('setIsSubmitting', true);
                commit('flushOutstandingDebounces');
                try {
                    await Promise.all(state.waitingPromises);
                } catch (error) {
                    Functions.addSentryBreadcrumb("api-call", "Failed when awaiting question promise before submitting survey");
                }
                commit('setToSubmitted');
                commit('clearAllPromises');
                let surveyAnswerIm = getters.getSurveyAnswerInputModel;
                try {
                    let result = await Instance.call(getters.getType, api => api.post(endpoint, surveyAnswerIm, { params: state.dataParameters }));
                    commit('setIsSubmitting', false);
                    if (result.status === 200) {
                        commit('surveyAnswerSent');
                        dispatch('surveyAnswerSubmitted', result.data);
                        return true;
                    } else {
                        commit('handleErrorWhenSubmit');
                    }
                } catch (error) {
                    commit('handleErrorWhenSubmit');
                }
            }
            return false;
        },
        surveyAnswerSubmitted(_, submitResponse) {
            if (submitResponse.continue) {
                var redirecUrl = submitResponse.continue;
                if (redirecUrl.includes("{domain}")) {
                    var domain = Functions.getCompanySubdomain(router.currentRoute.query);
                    redirecUrl = redirecUrl.replace("{domain}", domain);
                }
                setTimeout(() => { window.location.href = redirecUrl; }, 400);
            }
        },
        async changeLanguage({ commit, dispatch, state, getters }, language) {
            const endpoint = 'changelanguage';
            commit('setUserLanguageToLoad', language.languageIso)
            commit('flushOutstandingDebounces');
            try {
                await Promise.all(state.waitingPromises);
            } catch (error) {
                Functions.addSentryBreadcrumb("api-call", "Failed when awaiting promise before chaning language");
            }
            commit('clearAllPromises');
            let apiCall = api => api.post(endpoint, {}, { params: { ...state.dataParameters, preferredLanguage: language.language } });
            if (getters.isPreview) {
                apiCall = api => api.get('preview', { params: { ...state.dataParameters, preferredLanguage: language.language } });
            }
            try {
                const result = await Instance.call(getters.getType, apiCall);
                if (result.status === 200 || result.status === 302) {
                    state.surveyAnswerBaseModel.data.sections.forEach(section => {
                        section.questions.forEach(question => {
                            if(question.savedAnswer !== null){
                                const resultSection = result.data.sections.find(resultSection => resultSection.id === section.id);
                                if (resultSection) {
                                    const resultQuestion = resultSection.questions.find(resultQuestion => resultQuestion.id === question.id);
                                    if (resultQuestion) {
                                        resultQuestion.savedAnswer = question.savedAnswer;
                                    }
                                }
                            }
                        })
                    })
                    dispatch('dataLoadedSuccessful', result.data);
                } else {
                    //TODO handle failure
                }
            } catch (error) {
                //TODO handle failure
                Functions.addSentryBreadcrumb("api-call", "Failed to fetch survey in new language");
            }
        },
        acceptLegalModel({ commit }, hasConsentedToTerms) {
            commit('acceptLegalModel', hasConsentedToTerms);
        },
        unansweredHighlighTriggered({ commit }) {
            commit('highlightUnansweredQuestions');
        },
        updateUserLanguage({ commit }, language) {
            loadLanguageAsync(language).then(lang => {
                commit("setUserLanguage", lang);
            });
        },
        updateUserLanguageInitial({ commit }, language) {
            loadLanguageAsync(language, false).then(lang => {
                commit("setUserLanguage", lang);
            });
        },
        themeIsLoading({ commit }) {
            commit("setThemeIsLoading");
        },
        themeFinishedLoading({ commit }) {
            commit("setThemeFinishedLoading");
        },
    },
    getters: {
        getAllQuestions: (state) => {
            return state.surveyAnswerBaseModel.data.sections.flatMap(s => s.questions);
        },
        getSurveyAnswerInputModel: (state, getters) => {
            return {
                termsAccepted: state.surveyAnswerBaseModel.data.employee.legalModel.hasConsentedToTerms,
                answers: getters.getAllQuestions.map(q => q.savedAnswer).filter(Functions.isValidAnswer).map(sa => {
                    if (sa.comment?.trim() === '') {
                        sa.comment = null;
                    }
                    return sa;
                }),
            };
        },
        isDataParametersLoaded: (state) => {
            return state.dataParameters !== null;
        },
        isByKey: (state) => {
            return !!state.dataParameters && !!state.dataParameters.accessKey;
        },
        isPreview: (state) => state.isPreview,
        getType: (_, getters) => {
            return getters.isByKey ? ApiTypes.key : ApiTypes.user;
        },
        getSurveyAnswerBaseModel: (state, getters) => {
            return {
                ...state.surveyAnswerBaseModel,
                data: {
                    ...state.surveyAnswerBaseModel.data,
                    sections: state.surveyAnswerBaseModel.data.sections.map(section => ({
                        ...section,
                        questions: Functions.sortConditionalQuestions(section.questions.filter(q => Functions.isQuestionVisible(q, getters.getAllQuestions)))
                    }))
                }
            };
        },
        isSurveyAnswerBaseModelLoaded: (state) => {
            return state.surveyAnswerBaseModel.loadingState === LoadingState.success;
        },
        cssThemeIsLoading: (state) => {
            return state.cssThemeIsLoading;
        },
        getAnswer: (state, getters) => (questionId) => {
            let question = getters.getAllQuestions.find(q => q.id === questionId);
            return question != null ? question.savedAnswer : null;
        },
        getCommentDebounceFunction: (state) => (questionId) => state.debouncedPostSpecificComment.get(questionId),
        isPartiallyAnswered: (state) => state.surveyAnswerBaseModel.data !== null && state.surveyAnswerBaseModel.data.survey.surveyAnswerStatus === SurveyAnswerStatus.partiallyAnswered,
        isCompleted: (state) => state.surveyAnswerBaseModel.data !== null && state.surveyAnswerBaseModel.data.survey.surveyAnswerStatus === SurveyAnswerStatus.completed,
        isLegalAccepted: (state) => state.surveyAnswerBaseModel.data !== null && state.surveyAnswerBaseModel.data.employee.legalModel?.hasConsentedToTerms,
        isReadyToSubmit: (state, getters) => {
            return state.surveyAnswerBaseModel.changesToSubmit &&
                state.surveyAnswerBaseModel.data !== null &&
                getters.isLegalAccepted &&
                state.surveyAnswerBaseModel.data.sections
                    .flatMap(s => s.questions)
                    .filter(q => q.required && Functions.isQuestionVisible(q, getters.getAllQuestions))
                    .map(q => q.savedAnswer)
                    .every(Functions.isValidAnswer);
        },
        getFirstUnansweredQuestionIdIgnoreFirst: (state, getters) => {
            const question = getters.getAllQuestions.find(Functions.isUnansweredQuestion);
            const firstQuestion = getters.getAllQuestions.find(() => true);
            return question != null && question !== firstQuestion ? question.id : null;
        },
        getFirstRequiredUnansweredQuestionId: (state, getters) => {
            const question = getters.getAllQuestions.find(q => 
                q.required && Functions.isQuestionVisible(q, getters.getAllQuestions) && Functions.isUnansweredQuestion(q)
            );
            return question != null ? question.id : null;
        },
        getAllRequiredUnAnsweredQuestionsIds: (state, getters) => {
            const questionIds = getters.getAllQuestions.filter(q => 
                q.required && Functions.isQuestionVisible(q, getters.getAllQuestions) && Functions.isUnansweredQuestion(q)
            ).map(r => r.id);
            return questionIds;
        },
        getNumberOfUnansweredRequiredQuestions: (state, getters) => {
            return state.surveyAnswerBaseModel.data
                .sections
                .flatMap(s => s.questions)
                .filter(q => q.required && Functions.isQuestionVisible(q, getters.getAllQuestions) && !Functions.isValidAnswer(q.savedAnswer)).length;
        },
        getAvailableLanguages: (state) => state.surveyAnswerBaseModel.data.survey.availableLanguages,
        getContentOfLegalModel: (state) => state.surveyAnswerBaseModel.data && state.surveyAnswerBaseModel.data.employee.legalModel?.content || null,
        hasBeenSubmitted: (state, getters) => getters.isSurveyAnswerBaseModelLoaded && state.surveyAnswerBaseModel.data.survey.isSubmitted,
        getNumberOfAnsweredQuestion: (state, getters) => {
            if (state.surveyAnswerBaseModel.data == null) {
                return {
                    value: 0,
                    max: 0,
                };
            }
            let totalAnswers = state.surveyAnswerBaseModel.data
                .sections
                .flatMap(s => s.questions)
                .filter(q => q.required && Functions.isQuestionVisible(q, getters.getAllQuestions))
                .map(q => q.savedAnswer);
            return {
                value: totalAnswers.filter(Functions.isValidAnswer).length,
                max: totalAnswers.length,
            };
        },
        getEmployeeId: (state) => {
            return state.surveyAnswerBaseModel.data.employee.id;
        },
        getCssTheme: (state) => {
            return state.cssTheme;
        },
        highlightedHasBeenTriggered: (state) => {
            return state.highlightUnansweredQuestions;
        },
        userHasAcceptedToS: (state) => {
            return state.surveyAnswerBaseModel.data.employee.legalModel.hasConsentedToTerms;
        },
        isQuestionHighlighted: (state, getters) => (questionId) => {
            return state.highlightUnansweredQuestions &&
                getters.getAllRequiredUnAnsweredQuestionsIds.some(id => id === questionId);
        },
        getUserLanguage: (state) => {
            return state.userLanguage;
        },
        getUserLanguageToLoad: (state) => {
            return state.userLanguageToLoad;
        },
        getCurrentSurveyLanguage: (state) => {
            if (state.surveyAnswerBaseModel.data) {
                return state.surveyAnswerBaseModel.data.survey.currentLanguage;
            }
            return {};
        },
        getOrganisationInfo: (state) => {
            if (state.surveyAnswerBaseModel.data) {
                return {
                    name: state.surveyAnswerBaseModel.data.organization.orgEntityName,
                    orgLogo: state.surveyAnswerBaseModel.data.organization.orgEntityLogoUrl,
                    surveyLogo: state.surveyAnswerBaseModel.data.organization.surveyLogoUrl,
                };
            }
            return {};
        },
        getIsSubmitting: (state) => {
            return state.isSubmitting;
        },
        getSubmitSuccess: (state) => {
            return state.submitSuccess
        },
        getError: (state) => {
            return state.error
        }
    },
});

export default store;