<template>
    <header class="headroom" :class="{'headroom--unpinned': scrolled}"  v-on:scroll="handleScroll">
        <div class="header-inner">
            <div class="logotyp-wrapper">
                <img :src="logoUrlComputed" :alt="organizationNameComputed" />
            </div>
            <div class="select-language-wrapper">
                <button class="btn btn-primary show-welcome-modal-icon" @click="$emit('show-welcome-modal')" :aria-label="$t('labels.showIntroModal')">
                    <i class="show-welcome-modal-icon fal fa-info-circle"></i>
                </button>
                <select-language />
            </div>
        </div>
        <progress-bar />
    </header>
</template>

<script>
import ProgressBar from "./ProgressBar.vue";
import SelectLanguage from "./SelectLanguage.vue";
import { mapGetters } from "vuex";
import defaultLogo from "../assets/img/populum-smalltext-white150.png";
export default {
    name: "BrandedHeader",
    components: {
        ProgressBar,
        SelectLanguage,
    },
    emits: ['show-welcome-modal'],
    data() {
        return {
            limitPosition: 100,
            scrolled: false,
            lastPosition: 0,
        };
    },
    computed: {
        ...mapGetters(["getOrganisationInfo"]),
        logoUrlComputed(){
            var url = this.getOrganisationInfo.orgLogo;
            if(url){
                return url;
            }
            return defaultLogo;
        },
        organizationNameComputed(){
            var name = this.getOrganisationInfo.name;
            if(name){
                return name;
            }
            return "Logo";
        },
    },
    methods: {
        // --- https://codepen.io/kode88/pen/XRpXej
        handleScroll() {
            if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
                this.scrolled = true;
                // move up!
            } 
            if (this.lastPosition > window.scrollY) {
                this.scrolled = false;
                // move down
            }
            this.lastPosition = window.scrollY;
            // this.scrolled = window.scrollY > 250;
        },
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    unmounted() {
        window.removeEventListener("scroll", this.handleScroll);
    },  
}
</script>
<style lang="scss" scoped>
header {
    height: 54px;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: $zindex-sticky;
    .header-inner {
        //--- Flex in the nav
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1em;
        height: 100%;
    }
}
// --- Scroll settings
.headroom {
    will-change: transform;
    transition: transform 200ms linear;
}
.headroom--pinned {
    transform: translateY(0%);
}
.headroom--unpinned {
    transform: translateY(-101%);
}

//--- Logotype wrapper
.logotyp-wrapper {
    width: 50%;
    > img {
        max-width: 100px;
        max-height: 40px;
    }
}
//--- Language selector
.select-language-wrapper {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    > div {
        display: flex;
        justify-content: flex-end;
        flex-grow: 1;
        max-width: 300px;
    }
    button.btn{
        padding: 5px;
        border: 0;
        .show-welcome-modal-icon{
            width: 35px;
            height: 35px;
            margin: 0 10px;
            cursor: pointer;
            &::before{
                width: 35px;
                height: 35px;
                font-size: 35px;
            }
        }
    
    }
}


</style>
