

<template>
    <question-wrapper :id="'scrollid-' + question.id" :questionId="question.id" :isInstructionWithoutChoices="isInstructionWithoutChoices">
        <template #questionInstruction="{paddingLeft}">
            <question-text-group :question="question" :isInstructionWithoutChoices="isInstructionWithoutChoices" :paddingLeft="paddingLeft" class="question-instructions" />
        </template>
        <template #question="{paddingLeft}" v-if="!isInstructionWithoutChoices">
            <smiley-question v-if="isSmileyButton" :question="question" @changed="answerIsSelected" :paddingLeft="paddingLeft" />
            <smiley-question v-else-if="isHME" :isHME="true" :question="question" @changed="answerIsSelected" :paddingLeft="paddingLeft" />
            <radio-question v-else-if="isRadioOrYesNoType" :question="question" @changed="answerIsSelected" :paddingLeft="paddingLeft" />
            <numbered-choice-question v-else-if="isNumberedChoiceQuestion" :question="question" @changed="answerIsSelected" :paddingLeft="paddingLeft" />
            <checkbox-question v-else-if="isCheckbox" :question="question" @changed="answerIsSelected" :paddingLeft="paddingLeft" />
            <enps-question v-else-if="isENPS" :question="question" @changed="answerIsSelected" />
            <comment-text-area v-if="hasCommentTextArea" :isPureTextQuestion="isPureTextQuestion" :isShortTextQuestion="isShortTextQuestion" :hasBeenTriggered="commentIsTriggered" :question="question" :label="getCommentTextAreaLabel" :inputName="`q${question.id}comment`" :max-rows="20" :paddingLeft="paddingLeft" />
        </template>
        <template #nextQuestionButton v-if="showScrollToNext">
            <scroll-to-next-question-button :disabled="disableScrollToNext" :linkTo="nextQuestionId" />
        </template>
    </question-wrapper>    
</template>

<script>
import QuestionWrapper from './QuestionWrapper.vue';
import QuestionTextGroup from './QuestionTextGroup.vue';
import SmileyQuestion from './question_types/SmileyQuestion.vue';
import RadioQuestion from './question_types/RadioQuestion.vue';
import NumberedChoiceQuestion from './question_types/NumberedChoiceQuestion.vue';
import CheckboxQuestion from './question_types/CheckboxQuestion.vue';
import EnpsQuestion from './question_types/EnpsQuestion.vue';
import CommentTextArea from './CommentTextArea.vue';
import ScrollToNextQuestionButton from './ScrollToNextQuestionButton.vue';
import { QuestionType, CommentType } from '../helpers/constants';
import { Functions } from '../helpers/functions';

export default {
    name: "Question",
    components: { 
        QuestionWrapper, 
        QuestionTextGroup, 
        SmileyQuestion,
        CommentTextArea,
        ScrollToNextQuestionButton,
        RadioQuestion,
        NumberedChoiceQuestion,
        CheckboxQuestion,
        EnpsQuestion,
    },
    props:{
        question: {
            type: Object,
            required: true,
            default: () => {},
        },
        nextQuestionId: {
            type: Number,
            default: null,
        },
    },
    computed: {
        isSmileyButton() {
            return this.question.questionType === QuestionType.smileyButton || this.question.questionType === QuestionType.smileyButtonWithCommentForAllChoices;
        },
        isHME() {
            return this.question.questionType === QuestionType.hME || this.question.questionType === QuestionType.hMEWithCommentForAllChoices;
        },
        isYesNo(){
            return this.question.questionType === QuestionType.yesNo;
        },
        isRadioOrYesNoType(){
            return this.question.questionType === QuestionType.radio || this.isYesNo;
        },
        isPureTextQuestion(){
            return this.question.questionType === QuestionType.shortText || this.question.questionType === QuestionType.longText;
        },
        isShortTextQuestion(){
            return this.question.questionType === QuestionType.shortText;
        },
        isNumberedChoiceQuestion(){
            return this.question.questionType === QuestionType.numberedWithChoiceValue;
        },
        isCheckbox(){
            return this.question.questionType === QuestionType.checkbox;
        },
        isENPS(){
            return this.question.questionType === QuestionType.eNPS;
        },
        isInstructionWithoutChoices(){
            return this.question.questionType === QuestionType.instructionWithoutChoices || this.question.questionChoices.length === 0;
        },
        hasCommentTextArea(){
            return this.isPureTextQuestion || this.question.commentType === CommentType.optional || this.question.commentType === CommentType.required;
        },
        getCommentTextAreaLabel() {
            if (this.isPureTextQuestion) {
                return this.question.instruction;
            }
            if (this.question.commentInstruction) {
                return this.question.commentInstruction;
            }
            let savedAnswer = this.question.savedAnswer;
            if (!Functions.isValidAnswer(savedAnswer)) {
                return "";
            }
            let selectedChoiceValue = this.question.questionChoices.find(qc => qc.id === savedAnswer.questionChoiceIds[0]).numericValue;
            if (!Number.isFinite(selectedChoiceValue)) {
                return "";
            }
            if (this.isYesNo) {
                return "";
            }
            let threshHold = Functions.positiveCommentThreshold(this.question.questionType, this.question.questionChoices.filter(ch => ch.numericValue !== null).length);
            return selectedChoiceValue > threshHold ? this.$t("comment.howToMaintain") : this.$t("comment.howToImprove");
        },
        disableScrollToNext() {
            let savedAnswer = this.question.savedAnswer;
            let hasAnswered = Functions.isValidAnswer(savedAnswer);
            return !hasAnswered && this.question.required;
        },
        showScrollToNext(){
            return !this.isInstructionWithoutChoices && this.nextQuestionId !== null;
        },
    },
    data() {
        return {
            commentIsTriggered: false,
        };
    },
    mounted() {
        if(this.isPureTextQuestion){
            this.commentIsTriggered = true;
        }
    },
    methods: {
        answerIsSelected(answer) {
            if(!this.commentIsTriggered && Functions.shouldShowCommentOnChoiceSelect(answer)) {
                this.commentIsTriggered = true;
            }
        },
    },
}
</script>
<style lang="scss" scoped>
    .question-text + .pop-popover {
        display: inline;
    }
    .question-instructions{
        margin-bottom: 1.5em;
        &:last-child {
            margin-bottom: 0;
        }
    }
</style>