

<template>
    <fieldset>
        <legend style="display: none;">{{question.instruction}}</legend>
        <ul>
            <li v-for="choice in questionChoicesComputed" :key="`qc${question.id}:${choice.id}`">
                <pop-checkbox
                    :choise-id="`qc${question.id}:${choice.id}`"
                    :name="`checkbox${question.id}`"
                    :choice="choice.choice"
                    :startValue="isSelected(choice.id)"
                    :model="choice"
                    :paddingLeft="paddingLeft"
                    :isClicked="selectChoice"
                />
            </li>
        </ul>
    </fieldset>
</template>

<script>
import { mapActions } from 'vuex';
import PopCheckbox from "../PopCheckbox.vue";
export default {
    name: "CheckboxQuestion",
    components: { PopCheckbox },
    emits: ['changed'],
    props: {
        question: {
            type: Object,
            required: true,
            default: () => {},
        },
        paddingLeft: {
            type: Number,
            required: false,
        },
    },
    computed: {
        questionChoicesComputed() {
            return this.question.questionChoices || [];
        },
    },
    methods: {
        ...mapActions(['postSpecificQuesion']),
        selectChoice(isChecked, choice){
            let currentAnswer;
            
            if(this.question.savedAnswer === null){
                currentAnswer = {questionChoiceIds: [], comment: null, questionId: this.question.id};
            } else {
                currentAnswer = this.question.savedAnswer;
            }

            if(currentAnswer.questionChoiceIds != null && currentAnswer.questionChoiceIds.some(id => id === choice.id)){
                currentAnswer.questionChoiceIds = [...currentAnswer.questionChoiceIds].filter(id => id !== choice.id);
            }else{
                currentAnswer.questionChoiceIds = currentAnswer.questionChoiceIds || [];
                currentAnswer.questionChoiceIds = [...currentAnswer.questionChoiceIds, choice.id];
                this.$emit('changed', {choiceValue: choice.numericValue, commentType: this.question.commentType, questionType: this.question.questionType });
            }
            this.postSpecificQuesion(currentAnswer);
        },
        isSelected(choiceId){
            return this.question.savedAnswer !== null && 
                this.question.savedAnswer.questionChoiceIds !== null && 
                this.question.savedAnswer.questionChoiceIds.some(id => id === choiceId)
        },
    },

    
}
</script>
<style lang="scss" scoped>
.question-text + .pop-popover {
    display: inline;
}
li {
    list-style: none;
}
</style>