<template>
    <pop-button-component class="pop-btn scoped-btn" :disabled="disabled" :class="type" :variant="variant" :size="size" :block="block" @click="onClick" >
        <!-- @slot Default to label prop -->
        <slot>
            <span v-show="spinner" role="status" class="spinner-border waiting-api"></span>
            <i v-if="icon" v-show="!spinner" :class="icon" class="btn-icon"></i>{{ label }}
        </slot>
    </pop-button-component>
</template>

<script>
import PopButtonComponent from "./PopButtonComponent.vue";

export default {
    name: "PopButton",
    components: {
        PopButtonComponent,
    },
    props: {
        disabled: {
            type: Boolean,
            required: false,
        },
        type: {
            type: String,
            required: false,
        },
        variant: {
            type: String,
            required: false,
        },
        size: {
            type: String,
            required: false,
        },
        block: {
            type: Boolean,
            required: false,
            default:true,
        },
        label: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: false,
        },
        spinner: {
            type: Boolean,
            required:false,
            default: false,
        },
        onClick: {
            type: Function,
            required: false,
            default: () => {},
        },
    },
};
</script>

<style lang="scss">
.pop-btn.scoped-btn {
    /* --- Default button */
    &.btn {
        font-size: 1em;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 2%;
        border: inherit;
        padding: 15px;
        transition: all ease-in-out 200ms;
        border-radius: 0;
        padding: 15px 30px;
    }
    &.btn:disabled {
        cursor: not-allowed;
        transform: inherit;
        box-shadow: inherit;
        opacity: 1;
    }
    /* --- BTN Position */
    &.btn.center {
        margin: 0 auto;
        display: block;
    }
    /* --- Primary button */
    &.btn.btn-primary {
        line-height: 24px;
        padding: 15px 30px;
    }
    &.btn.btn-primary:disabled {
    cursor: not-allowed;
    }
    /* --- Submit */
    &.btn.btn-submit {
        line-height: 24px;
        padding: 15px 30px;
        display: flex;
        &.btn-icon {
            padding-right: 20px;
            position: inherit;
        }
        .check {
            opacity: 0;
            transform: translate(-25px, 0);
        }

    }
    &.btn.btn-submit .material-icons {
        transition: all 200ms linear;
    }
    /* --- Secondary button */
    &.btn.btn-secondary {
        padding: 12px 30px;
        border: 0;
        transition: all ease-in-out 200ms;
    }
    &.btn.btn-secondary:disabled {
        cursor: not-allowed;
    }
    /* --- Icon button */
    &.btn .btn-icon {
        padding-right: 10px;
        position: relative;
        font-size: 24px;
    }
    &.btn .btn-icon.pulseanimation  {
        animation: pulseanimation 2000ms infinite linear;
    }
    &.btn .btn-icon.arrowdownanimation  {
        animation: arrowdownanimation 700ms infinite ease-in-out;
    }
    &.btn.disabled {
        .pulseanimation, .arrowdownanimation {
            animation: none;
        }
    }
    /* --- Close button */
    &.btn.btn-close {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /* --- BTN Link */
    &.btn-link {
        background-color: transparent;
        font-size: 0.9em;
        padding: 0;
    }
    @media(hover: hover) and (pointer: fine) {
        &.btn.btn-submit {
            &:hover {
                .send {
                transform: translate(80px, 0);
                }
            }
        }
        &.btn-link{
            &:hover {
                background-color: transparent;
                box-shadow: inherit;
            }
        }
    }
    span.spinner-border.waiting-api {
        width: 24px;
        height: 24px;
        color: inherit;
        margin-right: 2px;
    }
}
</style>
