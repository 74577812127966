

<template>
    <fieldset>
        <legend style="display: none;">{{question.instruction}}</legend>
        <ul>
            <smiley-button 
                v-for="choice in questionChoicesComputed" 
                :key="`qc${question.id}:${choice.id}`" 
                :questionChoice="choice" 
                :selectedChoices="selectedComputed" 
                :isHME="isHME"
                :paddingLeft="paddingLeft"
                @click="selectChoice(choice)"
            />
        </ul>
    </fieldset>
</template>

<script>
import { mapActions } from 'vuex';
import SmileyButton from "./SmileyButton.vue";
export default {
    name: "SmileyQuestion",
    components: { SmileyButton },
    emits: ['changed'],
    props: {
        question: {
            type: Object,
            required: true,
            default: () => {},
        },
        isHME: {
            type: Boolean,
            required: false,
            default: false,
        },
        paddingLeft: {
            type: Number,
            required: false,
        }
    },
    computed: {
        questionChoicesComputed() {
            return this.question.questionChoices || [];
        },
        selectedComputed() {
            return this.question.savedAnswer && this.question.savedAnswer.questionChoiceIds || [];
        },
    },
    methods: {
        ...mapActions(['postSpecificQuesion']),
        selectChoice(choice){
            let questionAnswerIm = { questionChoiceIds: [choice.id],questionId: this.question.id, comment: null }
            if(this.question.savedAnswer !== null){
                questionAnswerIm.comment = this.question.savedAnswer.comment
            }
            if(this.question.savedAnswer !== null && this.question.savedAnswer.questionChoiceIds != null && this.question.savedAnswer.questionChoiceIds.some(id => id === choice.id)){
                questionAnswerIm.questionChoiceIds = [];
            } else {
                this.$emit('changed', {choiceValue: choice.numericValue, commentType: this.question.commentType, questionType: this.question.questionType });
            }
            this.postSpecificQuesion(questionAnswerIm);
        },
    },

    
}
</script>
<style lang="scss" scoped>
    .question-text + .pop-popover {
        display: inline;
    }
</style>