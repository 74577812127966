<template>
    <div
        class="progress-bar"
        :class="[
            {
                'progress-bar-striped': striped || animated,
                'progress-bar-animated': animated,
            },
            variant ? `bg-${variant}` : ''
        ]"
        :style="{width: barWidthComputed}"
        role="progressbar"
        :aria-valuenow="value"
        :aria-valuemin="min"
        :aria-valuemax="max"
    >
        <slot>
            {{ labelComputed }}
        </slot>
    </div>    
</template>
<script setup>
import { computed } from "vue"

const props = defineProps({
    value: {
        type: Number,
        default: 0,
    },
    min: {
        type: Number,
        default: 0,
    },
    max: {
        type: Number,
        default: 100,
    },
    showProgress: {
        type: Boolean,
        default: false,
    },
    showValue: {
        type: Boolean,
        default: false,
    },
    precision: {
        type: Number,
        default: 0,
    },
    animated: {
        type: Boolean,
        default: false,
    },
    striped: {
        type: Boolean,
        default: false,
    },
    variant: {
        type: String,
        default: null,
    },
})

const progress = computed(() => {
    return (props.value + props.min) / (props.max + props.min) * 100
})

const barWidthComputed = computed(() => {
    return `${progress.value}%`
})

const labelComputed = computed(() => {
    if (props.showValue) {
        return props.value.toFixed(props.precision)
    }
    if (props.showProgress) {
        progress.value.toFixed(props.precision)
    }
    return null
})
</script>
<style lang="scss" scoped>

</style>