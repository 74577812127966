<template>
    <div>
        <!-- Using modifiers -->
        <button @click="toggleCollapse" type="button" class="m-1 btn btn-link">{{ popToggle.title }}<i class="fal fa-chevron-down"></i></button>
        <!-- Element to collapse -->
        <pop-collapse id:="popToggle.id" :model-value="show">
            <div class="card">
                <div class="card-body">{{ popToggle.explantoryText }}</div>
            </div>
        </pop-collapse>
    </div>
</template>

<script>
import PopCollapse from './PopCollapse.vue';
export default {
    name: "PopToggle",
    components: {
        PopCollapse
    },
    props: {
        popToggle: {
            type: Object,
            required: true,
            default: () => ({
                id: '',
                title: '',
                explantoryText: '',
            }),
        },
    },
    data() {
        return {
            show: false,
        }
    },
    methods: {
        toggleCollapse(){
            this.show ? this.show = false : this.show = true;
        }
    }
}
</script>

<style lang="scss" scoped>
.card {
    border-radius: 2px;
    .card-body {
        padding: 0.5em;
        font-size: 0.8em;
    }
}
</style>