import { ApplicationInsights } from '@microsoft/applicationinsights-web'
// import AuthService from '../services/Auth/AuthenticationService'

const isLocal = import.meta.env.VITE_ENV === 'local';
const pathIdRegex = (/\/[ku]\/([a-z0-9_-]*)/ig);

const appInsights = new ApplicationInsights({ config: {
    connectionString: import.meta.env.VITE_APPLICATION_INSIGHTS_CONNECTION_STRING ?? "",
    instrumentationKey: "",
    samplingPercentage: 100,
    disableCookiesUsage: true,
    appId: "survey-frontend",
    disableInstrumentationKeyValidation: isLocal,
    disableExceptionTracking: isLocal,
    disableTelemetry: isLocal,
    enableAutoRouteTracking: true,
    /* ...Other Configuration Options... */
} });

appInsights.loadAppInsights();
appInsights.trackPageView();
appInsights.addTelemetryInitializer(e => {
    let uri = e.ext.trace.name;
    let foundId = pathIdRegex.exec(uri);
    if (foundId) {
        let entirePathId = foundId[0];
        let id = foundId[1];
        let replacedPathId = entirePathId.replace(id, "{id}");
        uri = uri.replace(pathIdRegex, replacedPathId);
        e.ext.trace.name = uri;
        e.tags["pathId"] = id;
    }
});
appInsights.context.application.ver = import.meta.env.VITE_BUILD_VERSION;

export default appInsights;
