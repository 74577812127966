
class ConfigServiceClass {
    AuthUrl() {
        return import.meta.env.VITE_AUTH_URL;
    }

    ApiUrl() {
        return import.meta.env.VITE_API_URL;
    } 

    WindowsApiUrl() {
        return import.meta.env.VITE_WIN_API_URL;
    } 

    AppUrl() {
        return window.location.origin;
    }
}

const ConfigService = new ConfigServiceClass();

export default ConfigService;
