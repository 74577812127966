<template>
    <div class="scroll-to-next-button">
        <pop-button
            :label="$t('question.nextQuestion')"
            variant="primary"
            :block="true"
            icon="arrowdownanimation fal fa-angle-double-down"
            :disabled="disabled"
            :onClick="scrollToNext"
        />
    </div>
</template>
<script>
import PopButton from "./PopButton.vue";

const SCROLL_OFFSET = -55;

export default {
    name: "ScrollToNextQuestionButton",
    components: {
        PopButton,
    },
    props: {
        linkTo: {
            type: Number,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: {
        scrollToNext() {
            const nextQuestionElement = document.getElementById(
                `scrollid-${this.linkTo}`
            );
            if (!nextQuestionElement) return;

            const y =
                nextQuestionElement.getBoundingClientRect().top +
                window.scrollY +
                SCROLL_OFFSET;
            window.scrollTo({ top: y });
        },
    },
};
</script>

<style lang="scss" scoped>
.btn {
    border-radius: 0 0 3px 3px;
}
</style>