
<template>
    <label class="numbered-choice" :class="[activeClass, { focusStyle : focusStyle }]" :style="styleComputed" :for="id">
        <span class="number">{{shownNumber}}</span>
        <input type="radio" :id="id" :name="name" :value="value" @click="$emit('click', model)" @focus="focusStyle = true" @blur="focusStyle = false" />
        <span class="label">{{ text }}</span>
    </label>
</template>
<script>
export default {
    name: "NumberedChoice",
    emits: ['click'],
    data: function() {
        return {
            focusStyle: false,
        };
    },
    props: {
        number: {
            type: Number,
            required: false,
        },
        id: {
            type: String,
            required: true,
            default: "Unknown ID",
        },
        name: {
            type:String,
            required: true,
        },
        text: {
            type:String,
            required: true,
            default: "Unknown label",
        },
        value: {
            type: Number,
            required: false,
        },
        selected: {
            type: Number,
            required: false,
        },
        model: {
            type: Object,
            required: true,
        },
        paddingLeft: {
            type: Number,
            required: false,
        },
    },
    computed: {
        activeClass() {
            return this.selected === this.value ? 'active' : '';
        },
        shownNumber(){
            return this.number === null ? '-' : `${this.number}`;
        },
        styleComputed() {
            let style = {};
            if (this.paddingLeft) {
                style.paddingLeft = this.paddingLeft + "em";
            }
            return style;
        }
    },
}
</script>
<style lang="scss" scoped>
//Parent li
label {
    height: 55px;
    display: block;
    transition: all ease-in 100ms;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
}
// Number
.number {
    text-align: center;
    line-height: 35px;
    width: 35px;
    height: 35px;
    transition: all ease-in 100ms;
    border-radius: 50%;
}

// Label text
.label {
    margin-left: 20px;
    font-size: 1em;
}

input[type="radio"] {
    visibility: visible !important;
    opacity: 0 !important;
}

.active {
    .number{
        font-weight: 400;
    }
    .label {
        font-weight: 400;
    }
}


</style>