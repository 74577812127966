<template>
    <transition
        name="collapse"
        @enter="emit('show')"
        @after-enter="emit('shown')"
        @leave="emit('hide')"
        @after-leave="emit('hidden')"
    >
        <div
            v-show="modelValue"
            class="pop-collapse"
        >
            <div ref="collapseContent">
                <slot
                    :visible="modelValueRef"
                    :close="close"
                ></slot>
            </div>
        </div>
    </transition>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref, toRef } from 'vue';

const props = defineProps({
    modelValue: {
        type: Boolean,
        deafult: false,
    },
    accordionParentId: {
        type: String,
        default: "",
    },
})

const emit = defineEmits(['update:modelValue', 'show', 'shown', 'hide', 'hidden'])

const modelValueRef = toRef(props, 'modelValue')

const close = () => emit('update:modelValue', false)
const open = () => emit('update:modelValue', true)
const toggle = () => emit('update:modelValue', !modelValueRef.value)

defineExpose({
    close,
    open,
    toggle,
})


const collapseContent = ref();

const collapseHeight = ref(0);

const contentResizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
        collapseHeight.value = `${entry.contentRect.height}px`
    }
});

onMounted(() => {
    contentResizeObserver.observe(collapseContent.value)
}) 

onBeforeUnmount(() => {
    contentResizeObserver.disconnect()
})

</script>
<style lang="scss" scoped>
.pop-collapse {
    height: v-bind(collapseHeight);
}

.collapse-enter-from,
.collapse-leave-to {
    height: 0;
}

.collapse-enter-active,
.collapse-leave-active {
    overflow: hidden;
    transition: height 0.35s ease;
}
</style>