

<template>
    <fieldset>
        <legend style="display: none;">{{question.instruction}}</legend>
        <ul>
            <li v-for="choice in questionChoicesComputed" :key="`qc${question.id}:${choice.id}`">
                <pop-radio-button
                    :id="`qc${question.id}:${choice.id}`"
                    :name="`radio${question.id}`"
                    :text="choice.choice"
                    :value="choice.id"
                    :model="choice"
                    :selected="selected"
                    :paddingLeft="paddingLeft"
                    @click="selectChoice"
                />
            </li>
        </ul>
    </fieldset>
</template>

<script>
import { mapActions } from 'vuex';
import PopRadioButton from "../PopRadioButton.vue";
export default {
    name: "RadioQuestion",
    components: { PopRadioButton },
    emits: ['changed'],
    props: {
        question: {
            type: Object,
            required: true,
            default: () => {},
        },
        paddingLeft: {
            type: Number,
            required: false,
        }
    },
    data() {
        return {
            selected: null,
        };
    },
    computed: {
        questionChoicesComputed() {
            return this.question.questionChoices || [];
        },
        selectedComputed() {
            return this.question.savedAnswer && this.question.savedAnswer.questionChoiceIds || [];
        },
    },
    mounted() {
        if(this.question.savedAnswer && this.question.savedAnswer.questionChoiceIds && this.question.savedAnswer.questionChoiceIds.length > 0){
            this.selected = this.question.savedAnswer.questionChoiceIds[0];
        }
        
    },
    methods: {
        ...mapActions(['postSpecificQuesion']),
        selectChoice(choice){
            let questionAnswerIm = { questionChoiceIds: [choice.id],questionId: this.question.id, comment: null }
            if(this.question.savedAnswer !== null){
                questionAnswerIm.comment = this.question.savedAnswer.comment
            }
            if(this.question.savedAnswer !== null && this.question.savedAnswer.questionChoiceIds != null && this.question.savedAnswer.questionChoiceIds.some(id => id === choice.id)){
                this.selected = null;
                questionAnswerIm.questionChoiceIds = [];
            } else {
                this.selected = choice.id;
                this.$emit('changed', {choiceValue: choice.numericValue, commentType: this.question.commentType, questionType: this.question.questionType });
            }
            this.postSpecificQuesion(questionAnswerIm);
        },
    },

    
}
</script>
<style lang="scss" scoped>
.question-text + .pop-popover {
    display: inline;
}
li {
    list-style: none;
}
</style>