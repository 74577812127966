
<template>
    <label class="pop-radio-button" :class="[activeClass, { focusStyle : focusStyle } ]" :style="styleComputed" :for="id">
        <input type="radio" :name="name" :value="value" :id="id" @click="$emit('click', model)" @focus="focusStyle = true" @blur="focusStyle = false"  />
        <span class="pop-radio-button-style"></span>
        <span class="text">{{ text }}</span>
    </label>
</template>
<script>

export default {
    name: "PopRadioButton",
    emits: ['click'],
    data: function() {
        return {
            focusStyle: false,
        };
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        name: {
            type:String,
            required: true,
        },
        value: {
            type: Number,
            required: false,
        },
        text: {
            type: String,
            required: true,
        },
        model: {
            type: Object,
            required: true,
        },
        selected: {
            type: Number,
            required: false,
        },
        paddingLeft: {
            type: Number,
            required: false,
        }
    },
    computed: {
        activeClass() {
            return this.selected === this.value ? 'active' : '';
        },
        styleComputed() {
            let style = {};
            if (this.paddingLeft) {
                style.paddingLeft = this.paddingLeft + "em";
            }
            return style;
        }
    },
}
</script>

<style lang="scss" scoped>
label.pop-radio-button {
    width: 100%;
    padding: 15px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    span.text {
        padding-left: 20px;
    }
    input[type="radio"] {
        visibility: visible !important;
        opacity: 0 !important;
        width: 0;
    }
}
//--- Default style
.pop-radio-button-style {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    transition: all ease-in-out 100ms;
    flex: none;
    position: relative;
    border: 1px solid #E6E6E6;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.25);
    background-color: white;
    &:after {
        content: "";
        position: absolute;
        display: none;
        top: 4px;
        left: 4px;
        width: 8px;
        height: 8px;
        box-sizing: border-box;
        border-radius: 50%;
    }
}
</style>