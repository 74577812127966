import MarkdownIt from "markdown-it";
const markdown = new MarkdownIt();
import { QuestionTypeThatTriggerSuggestions, CommentType, QuestionType, ConditionOperator } from './constants'
// import * as Sentry from "@sentry/vue";
import applicationInsights from '../helpers/applicationInsights.js'

const tagRegex = /(<\/?)([\w]+)(?:[^>]*)>/gm;
const selfClosingTags = ['br', 'hr'];
// const isLocal = import.meta.env.VITE_ENV === 'local';

// code from https://github.com/markdown-it/markdown-it/blob/master/docs/architecture.md#renderer 
let defaultRender = markdown.renderer.rules.link_open || function(tokens, idx, options, env, self) {
    return self.renderToken(tokens, idx, options);
  };
  
markdown.renderer.rules.link_open = function (tokens, idx, options, env, self) {
    let aIndex = tokens[idx].attrIndex('target');

    if (aIndex < 0) {
        tokens[idx].attrPush(['target', '_blank']);
    } else {
        tokens[idx].attrs[aIndex][1] = '_blank'; 
    }

    return defaultRender(tokens, idx, options, env, self);
};

function isConditionFulfilled(condition, allQuestions) {
    const target = allQuestions.find(q => q.id === condition.targetId);
    if (!target || !target.savedAnswer) {
        return false;
    }
    const answeredChoiceIds = target.savedAnswer.questionChoiceIds;
    if (answeredChoiceIds.length === 0) {
        return false;
    }
    if (!condition.choices.some(choice => answeredChoiceIds.includes(choice.id))) {
        return false;
    }
    return true;
}

export const Functions = {
    logError: function (error, message, additionalParameters = {}) {
        // const logError = {
        //     error: error,
        //     message: message,
        //     level: "Error",
        //     ...additionalParameters,
        // };
        // Sentry.captureException(logError);
        const logError = {
            exception: error,
            properties: { message, level: "Error", ...additionalParameters },
        };
        applicationInsights.trackException(logError);
    },
    addSentryBreadcrumb: function (category, message, data) {
        // Sentry.addBreadcrumb({
        //     category: category,
        //     message: message,
        //     data: data,
        //     // level: Sentry.Severity.Info,
        // });
        applicationInsights.trackEvent({ name: category, properties: { message, ...data } });
    },
    /**
     * 
     * @param {String} level one of 'info', 'warning', or 'error' 
     * @param {String} message 
     * @param {Object} data 
     */
    logMessage: function (level, message, data) {
        // Sentry.captureMessage(message, { level, ...data });
        applicationInsights.trackTrace({ message: message, properties: { level, ...data } });
    },
    renderMarkdown(text, withoutParagraphwrap = false) {
        if (!this.isString(text)) {
            return text;
        }
        if (withoutParagraphwrap) {
            let result = markdown.render(text.replace(/&nbsp;/g, ' ').split(':li:').join('\n* ').trim()).trim();
            if (result.startsWith('<p>') && result.endsWith('</p>')) {
                result = result.substring(3, result.length - 4)
            }
            return result;
        }
        // split done to convert the :li: that was historicly used to 
        return markdown.render(text.replace(/&nbsp;/g, ' ').split(':li:').join('\n* ').trim());
    },
    // code from https://stackoverflow.com/a/9436948
    isString(stringCandidate) {
        return typeof stringCandidate === 'string' || stringCandidate instanceof String;
    },
    isNil(value) {
        return value == null;
    },
    isStringNilOrWhitespace(string) {
        return this.isNil(string) || !this.isString(string) || string.trim().length === 0;
    },
    isValidAnswer(answer) {
        return answer != null && answer.questionChoiceIds != null && answer.questionChoiceIds.length > 0;
    },
    isUnansweredQuestion(question) {
        return question.questionChoices?.length > 0 &&
            (question.savedAnswer === null || question.savedAnswer.questionChoiceIds == null || question.savedAnswer.questionChoiceIds.length === 0);
    },
    //Code from ConditionHelper.cs in populumsurveyapi project. 
    isQuestionVisible(question, allQuestions) {
        if (question.visibilityConditions.length === 0) {
            return true;
        }
        const evaluations = question.visibilityConditions.map(x => ({ operator: x.operator, isFulfilled: isConditionFulfilled(x, allQuestions) }));
        const orEvaluations = [];
        var currentAndEvaluation = evaluations[0].isFulfilled;
        for (let evaluation of evaluations.slice(1)) {
            if (evaluation.operator === ConditionOperator.And) {
                currentAndEvaluation = currentAndEvaluation && evaluation.isFulfilled;
            }
            else {
                orEvaluations.push(currentAndEvaluation);
                currentAndEvaluation = evaluation.isFulfilled;
            }
        }
        orEvaluations.push(currentAndEvaluation);
        return orEvaluations.includes(true);
    },
    sortConditionalQuestions(questions) {
        const list = [...questions];
        for (let question of questions) {
            if (question.visibilityConditions.length === 0) {
                continue;
            }
            const targetQuestions = question.visibilityConditions.map(condition => questions.find(q => q.id === condition.targetId));
            const targetQuestionIndexes = targetQuestions.map(q => list.indexOf(q));
            const lastTargetIndex = Math.max(...targetQuestionIndexes);
            if (lastTargetIndex < 0) {
                continue;
            }
            const questionIndex = list.indexOf(question);
            // Place the conditional question right after the last target (if it is not already after it)
            if (questionIndex < lastTargetIndex) {
                list.splice(questionIndex, 1);
                list.splice(lastTargetIndex, 0, question);
            }
        }
        return list;
    },
    //Code from QuestionVM.cs in populummain project.
    questionHasComments(question) {
        return question.commentType === CommentType.optional || question.commentType === CommentType.required;
    },
    positiveCommentThreshold(questionType, questionsWithNumericValues) {
        switch (questionType) {
            case QuestionType.hME:
            case QuestionType.hMEWithCommentForAllChoices:
                return 3;
            case QuestionType.numberedWithChoiceValue:
                return Math.ceil(questionsWithNumericValues / 2);
            case QuestionType.eNPS:
                return 8;
            default:
                return 1;
        }
    },
    //Code from QuestionVM.cs in populummain project.
    triggerCommentThreshold(questionType, questionsWithNumericValues) {
        switch (questionType) {
            case QuestionType.hME:
                return 2;
            case QuestionType.numberedWithChoiceValue:
                return Math.floor(questionsWithNumericValues / 2);
            case QuestionType.eNPS:
                return 6;
            default:
                return 1;
        }
    },
    shouldShowCommentOnChoiceSelect(answer) {
        //Code from QuestionVM.cs in populummain project.
        const hasComments = this.questionHasComments(answer);
        const correctQuestionType = hasComments && QuestionTypeThatTriggerSuggestions.some(qt => qt === answer.questionType);
        if (!correctQuestionType || answer.choiceValue == null) {
            return false;
        }
        switch (answer.questionType) {
            case QuestionType.smileyButtonWithCommentForAllChoices:
            case QuestionType.hMEWithCommentForAllChoices:
            case QuestionType.numberedWithChoiceValue:
                    return true;
            case QuestionType.eNPS:
                if (hasComments) {
                    return true;
                }
                return false;
            default:
                return answer.choiceValue <= this.triggerCommentThreshold(answer.questionType, answer.questionsWithNumericValues);
        }
    },
    getSmileyIconClass(numericValue, isHME = false) {
        if (isHME) {
            switch (numericValue) {
                case 5:
                    return "smileyBlue";
                case 4:
                    return "smileyGreen";
                case 3:
                    return "smileyHME-3";
                case 2:
                    return "smileyYellow";
                case 1:
                    return "smileyRed";
                case null:
                default:
                    return "smileyGrey";
            }
        } else {
            switch (numericValue) {
                case 3:
                    return "smileyBlue";
                case 2:
                    return "smileyGreen";
                case 1:
                    return "smileyYellow";
                case 0:
                    return "smileyRed";
                case null:
                default:
                    return "smileyGrey";
            }
        }
    },
    thirdPersonPossessive(owner, language) {
        if (owner == null || owner.trim().length === 0) {
            return '';
        }
        switch (language) {
            case 'sv':
            case 'nb':
            case 'da':
                return this.swedishNorwegianAndDanishThirdPersonPossessive(owner);
            case 'en':
                return this.englishThirdPersonPossessive(owner);
            case 'fi':
                return this.finishThirdPersonPossessive(owner);
            default:
                return owner;
        }
    },
    englishThirdPersonPossessive(owner) {
        if (owner.toLowerCase().endsWith("s"))
            return `${owner}'`;
        return `${owner}'s`;
    },
    swedishNorwegianAndDanishThirdPersonPossessive(owner) {
        let ownedInLower = owner.toLowerCase();
        if (ownedInLower.endsWith("s") || ownedInLower.endsWith("x"))
            return owner;
        return `${owner}s`;
    },
    finishThirdPersonPossessive(owner) {
        let lastChar = owner.slice(-1);
        if (/[aoueiäöy]/.test(lastChar)) { //Finish vowels
            return `${owner}n`;
        }
        return `${owner}in`;
    },
    //code from https://stackoverflow.com/a/14130005
    htmlEncodeString(str) {
        return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
    },

    // ignores self closing tags. Becouse is mostly used for markdown. 
    // tag needs to have a closing tag of same type.
    stringContainsHtmlTags(str) {
        if (!this.isString(str) || str.length === 0) {
            return false;
        }
        let matches = [...str.matchAll(tagRegex)]
            .filter(m => m.length > 2)
            .map(m => ({ isClosing: m[1] === '</', tag: m[2] }));
        for (const match of matches) {
            if ((selfClosingTags.includes(match.tag) && !match.isClosing) || matches.some(m => m.tag === match.tag && m.isClosing !== match.isClosing)) {
                return true;
            }
        }
        return false;
    },
    getCompanySubdomain(query){
        let domain = query.d;
        if(domain){
            return domain;
        }
        return "app";
    },
};