<template>
    <div v-if="showThis" class="section-intro-wrapper" section="section">
        <div class="inner">
            <h2>{{computedName}}</h2>
            <div v-if="section.visible" v-html="computedInstruction" class="v-html instruction-text"></div>
        </div>
    </div>
</template>

<script>
import { Functions } from '../helpers/functions';
import { PiggyBackTarget } from '../helpers/constants';
import { mapGetters } from "vuex";
export default {
    name: "SectionIntro",
    props: {
        section: {
            type: Object,
        },
    },
    computed:{
        ...mapGetters(['getUserLanguage']),
        showThis(){
            if(this.isPiggyBackSection){
                return !this.section.hideTargetName && this.section.targetEntityName != null && this.section.targetEntityName.length > 0;
            }
            return this.section.visible && this.hasAnyTextComputed;
        },
        isPiggyBackSection(){
            return this.section.target;
        },
        computedName(){
            if(this.isPiggyBackSection){
                switch(this.section.target){
                    case PiggyBackTarget.Team:
                        return this.$t("shared.sentToTeam", {name: Functions.thirdPersonPossessive(this.section.targetEntityName, this.getUserLanguage)});
                    case PiggyBackTarget.Organization:
                        return this.$t("shared.sentToOrganization", {name: Functions.thirdPersonPossessive(this.section.targetEntityName, this.getUserLanguage)});
                    case PiggyBackTarget.Division:
                    default:
                        return this.$t("shared.sentTo", {name: this.section.targetEntityName});
                }
            }
            return this.section.name;
        },
        computedInstruction(){
            return Functions.renderMarkdown(this.section.instruction);
        },
        hasAnyTextComputed() {
            return (this.computedName && this.computedName.length > 0) || (this.section.instruction && this.section.instruction.length > 0);
        },
    },
}
</script>

<style lang="scss" scoped>
.section-intro-wrapper {
    max-width: 440px;
    margin: 0 auto;
    list-style: none;
    .inner {
        background-color: white;
        border-radius: 3px;
        height: auto;
        margin: 10px;
        padding: 2em;
        position: relative;
        overflow: hidden;
        &::before {
            content: "";
            width: 100%;
            height: 10px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            border-radius: 3px 3px 0 0;
        }
        h2 {
            font-size: 1.2em;
        }
        small {
            font-weight: 300;
        }
        p {
            font-size: 0.9em;
        }
        .instruction-text :last-child {
            margin-bottom: 0;
        }
    }
}
.question-text {
    max-width: 35ch;
    font-size: 1.5em;
    margin-bottom: 0;
}

</style>